import React from 'react';
import Checkbox from './Checkbox';
import SubmitButton from './SubmitButton';
import { Row, Col } from 'react-bootstrap';
import TextInput from './TextInput';
import MailchimpSubscribe from "react-mailchimp-subscribe"

export default class EmailForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            betaCheck: true,
            newsletterCheck: true,
            email: '',
            fname: '',
            lname: '',
            hovering: false,
        }
    }

    handleChange(e, name) {
        this.setState({
            [name]: e.target.value
        });
    }

    successMessage() {
      if(this.state.betaCheck && !this.state.newsletterCheck) {
        return `🎉 Welcome, ${this.state.fname}! You are now on the waitlist for beta access to our newest app!`
      } else if(!this.state.betaCheck && this.state.newsletterCheck) {
        return `🎉 Welcome, ${this.state.fname}! You are now subscribed to our newsletter!`
      } else if(this.state.betaCheck && this.state.newsletterCheck) {
        return `🎉 Welcome, ${this.state.fname}! You are now on the waitlist for beta access to our newest app and subscribed to our newsletter!`
      }
    }

    render() {

      const url = "https://thechurch.us18.list-manage.com/subscribe/post?u=46e2226ab3aada00346bc44a5&amp;id=34391775e5";

      return (
        <Col md={11} lg={9}>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <>
                <form
                  style={{
                    opacity: status !== null ? 0.05 : 1,
                    pointerEvents: status !== null ? 'none' : 'auto',
                  }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    var data = {
                      'EMAIL': this.state.email,
                      'FNAME': this.state.fname,
                      'LNAME': this.state.lname,
                    }

                    if(this.state.betaCheck) {
                      data['group[6322][1]'] = true
                    }

                    if(this.state.newsletterCheck) {
                      data['group[6322][2]'] = true
                    }

                    subscribe(data);
                  }}
                  onMouseEnter={() => this.setState({hovering: true})}
                  onMouseLeave={() => this.setState({hovering: false})}
              >
                  <Row className="mt-4">
                    <Col>
                      <Row>
                        <Col className="pr-0">
                          <TextInput
                            value={this.state.fname}
                            handleChange={(e) => this.handleChange(e, 'fname')}
                            disabled={status === "sending"}
                            hovering={this.state.hovering}
                            placeholder="First name"
                            type="name"
                            required={true}
                          />
                        </Col>
                        <Col>
                          <TextInput
                            value={this.state.lname}
                            handleChange={(e) => this.handleChange(e, 'lname')}
                            disabled={status === "sending"}
                            hovering={this.state.hovering}
                            placeholder="Last name"
                            type="name"
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <TextInput
                            value={this.state.email}
                            handleChange={(e) => this.handleChange(e, 'email')}
                            disabled={status === "sending"}
                            hovering={this.state.hovering}
                            placeholder="Email"
                            type="email"
                            required={true}
                          />
                          <SubmitButton 
                            sending={status === "sending"}
                            success={status === "success"}
                            disabled={!this.state.betaCheck && !this.state.newsletterCheck}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <Checkbox
                        label="Request beta access"
                        checked={this.state.betaCheck}
                        toggle={() => this.setState({betaCheck: !this.state.betaCheck})}
                        disabled={status === "sending"}
                        hovering={this.state.hovering}
                      />
                      <Checkbox
                        label="Subscribe to newsletter"
                        checked={this.state.newsletterCheck}
                        toggle={() => this.setState({newsletterCheck: !this.state.newsletterCheck})}
                        disabled={status === "sending"}
                        hovering={this.state.hovering}
                      />
                    </Col>
                  </Row>
                  
                </form>
                <Row style={{marginTop: '-100px'}}>
                    <Col className="text-center">
                    {status === "error" && <div style={{display: 'flex', flexDirection: 'row'}}><span>🙅‍♂️&nbsp;</span><div style={{ color: "#FF463A", fontWeight: '600' }} dangerouslySetInnerHTML={{__html: message}}/></div>}
                    {status === "success" && <div style={{ color: "#30D158", fontWeight: '600' }}>{this.successMessage()}</div>}
                    </Col>
                </Row>
              </>
            )}
          />
        </Col>
      );
    }
}