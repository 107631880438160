import React from 'react';

export default class SubmitButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hovering: false
        }
    }

    render() {

        const buttonStyle = {
            color: '#fff',
            // backgroundColor: this.state.hovering ? '#0E179B' : '#000000',
            backgroundColor: '#000000',
            border: 'none',
            borderRadius: '4px',
            height: '40px',
            width: '100px',
            fontWeight: '800',
            fontSize: '22px',
            letterSpacing: '0.5px',
            lineHeight: '30px',
            textTransform: 'uppercase',
            position: 'absolute',
            marginTop: '8px',
            marginLeft: '-108px',
            boxShadow: '0px 4px 12px 0px rgba(0,0,0,0.15)',
            opacity: (this.props.disabled && !this.props.success && !this.props.sending) ? 0.25 : 1,
            transition: '0.2s',
            outline: 'none'
        }

        const content = () => {
            if(this.props.sending) {
                return(
                    <span role="img" ariaLabel="sending" className="spinner">
                        ⏳
                    </span>
                )
            } else if(this.props.success) {
                return(
                    <span role="img" ariaLabel="success">
                        👍
                    </span>
                )
            } else {
                return(
                    <span role="img" ariaLabel="submit">
                        📨
                    </span>
                )
            }
        }
        
        return (
            <button 
                style={buttonStyle}
                disabled={this.props.disabled || this.props.success || this.props.sending}
                onMouseEnter={() => this.setState({hovering: true})}
                onMouseLeave={() => this.setState({hovering: false})}
            >
                { content() }
            </button>
        );
    }
}