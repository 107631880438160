import React from 'react';

export default class Checkbox extends React.Component {

    handleClick() {
        if(!this.props.disabled) {
            this.props.toggle();
        }
    }

    render() {

        const containerStyle = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            float: 'left',
            marginRight: '20px',
            cursor: this.props.disabled ? 'default' : 'pointer',
            // opacity: this.props.disabled ? 0.5 : 1
        }

        const checkboxStyle = {
            height: '20px',
            width: '20px',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            opacity: this.props.hovering && !this.props.disabled ? 1 : 0.6,
            transition: '0.2s'
        }

        const labelStyle = {
            fontWeight: '500',
            fontSize: '12px',
            color: this.props.hovering && !this.props.disabled ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)',
            marginLeft: '10px',
            transition: '0.2s'
        }

        const checkbox = () => {
            if(this.props.checked) {
                return(
                    <div style={checkboxStyle}>
                        <img src="/check_box.svg" alt="" />
                    </div>
                );
            } else {
                return(
                    <div style={checkboxStyle}>
                        <img src="/check_box_outline_blank.svg" alt="" />
                    </div>
                );
            }
        }
        
        return (
            <div 
                style={containerStyle}
                onClick={() => this.handleClick()}
            >

                { checkbox() }

                <span style={labelStyle}>
                    { this.props.label }
                </span>
            </div>
        );
    }
}