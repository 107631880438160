import React from 'react';
import { Col } from 'react-bootstrap';
import Particles from 'react-particles-js';

export default class ParticleBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            width: 0
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    updateWindowDimensions() {
        this.setState({ 
            width: window.innerWidth
        });
    }

    render() {

        const content = () => {
            if(this.state.width > 768) {
                return(
                    <Col>
                        <Particles 
                            height={460}
                            params={{
                                "particles": {
                                    "number": {
                                        "value": 56
                                    },
                                    "size": {
                                        "value": 3
                                    },
                                    "move": {
                                    "speed": 0.75
                                    },
                                    "lineLinked": {
                                    "opacity": 0.25
                                    }
                                },
                                "interactivity": {
                                "events": {
                                    "onClick": {
                                    "enable": true,
                                    "mode": "push"
                                    }
                                }
                                }
                            }}
                        />
                    </Col>
                );
            }
        }
        
        return (
            <>
                { content() }
            </>
        );
    }
}