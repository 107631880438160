import React from 'react';
import { Row, Col } from 'react-bootstrap';
import FadeIn from 'react-fade-in';

export default class Banner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hovering: false,
            open: true
        }
    }

    render() {

        const containerStyle = {
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            display: this.state.open ? 'block' : 'none',
            zIndex: '999999'
        }

        const bannerStyle = {
            width: '100%',
            height: '40px',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: this.state.hovering ? '#0F11C5' : '#0E179B',
            transition: '0.5s',
            color: '#fff',
            fontWeight: '800',
            fontSize: '14px',
            paddingTop: '3px'
        }

        const linkStyle = {
            textDecoration: 'underline',
            marginLeft: '10px'
        }

        const chevronStyle = {
            width: '18px',
            opacity: '0.6'
        }

        const closeContainerStyle = {
            height: '40px',
            width: '40px',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            position: 'absolute',
            left: '0',
            top: '-40px',
            cursor: 'pointer'
        }

        const closeStyle = {
            width: '18px',
            opacity: '0.6',
            marginTop: '3px'
        }
        
        return (
            <div 
                style={containerStyle}
                onMouseEnter={() => this.setState({hovering: true})}
                onMouseLeave={() => this.setState({hovering: false})}
            >
                <FadeIn 
                    delay={100}
                    transitionDuration={600}
                >
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSetFdkLnSqJb9tXQpz-g4zMz4M-_MU2UQOwff5Mc3AM9Vf_uw/viewform?usp=sf_link" target="_blank">
                        <Row className="m-0" style={bannerStyle}>
                            <Col className="text-center">
                                Looking for a job?
                                <span style={linkStyle}>
                                    Click here 
                                    <img src="/chevron_right.svg" alt="" style={chevronStyle} />
                                </span>
                            </Col>
                        </Row>
                    </a>
                    <div 
                        style={closeContainerStyle}
                        onClick={() => this.setState({open: false})}
                    >
                        <img src="/close.svg" alt="" style={closeStyle} />
                    </div>
                </FadeIn>
            </div>
        );
    }
}