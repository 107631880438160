import React from 'react';
import './App.scss';
import { Row, Col } from 'react-bootstrap';
import EmailForm from './components/EmailForm';
import ParticleBox from './components/ParticleBox';
import Banner from './components/Banner';

function App() {
  return (
    <Col className="bg">

      <Banner />
      
      <Row className="top-offset">
        <Col md={11} lg={10} className="mx-auto d-flex align-items-center justify-content-space-between">
          <Row className="w-100 m-0">
            <Col md={8}>

              <Row>
                <Col>
                  <div className="header-3">
                    Church, Inc.
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="header-1">
                    We're on a mission to use business and technology to build up and unify the global church.
                  </div>
                </Col>
              </Row>

              <Row>
                  <EmailForm />
              </Row>

            </Col>
            
            <ParticleBox />

          </Row>
        </Col>
      </Row>
      <Row className="footer m-0">
        <Col md={11} lg={10} className="mx-auto">
          <Row>
            <Col>
                👋&nbsp;
                <a href="mailto:stephen@thechurch.io">
                  stephen@thechurch.io
                </a>
            </Col>
            <Col className="text-right">
              <span className="label">
                © Copyright 2020 Church, Inc.
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

export default App;
