import React from 'react';

export default class TextInput extends React.Component {

    static defaultProps = {
        required: false
    }

    constructor(props) {
        super(props);
        this.state = {
            focused: false
        }
    }

    render() {

        const inputStyle = {
            backgroundColor: (this.state.focused || this.props.hovering || this.props.value !== '') ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.75)',
            border: 'none',
            outline: 'none',
            borderRadius: '4px',
            paddingLeft: '20px',
            paddingRight: '116px',
            height: '56px',
            color: '#000',
            fontSize: '16px',
            width: '100%',
            boxShadow: '0px 4px 12px 0px rgba(0,0,0,0.15)',
            fontWeight: '500',
            transition: '0.2s'
        }
        
        return (
            <input
              type={this.props.type}
              required={this.props.required}
              placeholder={this.props.placeholder}
              style={inputStyle}
              onChange={(e) => this.props.handleChange(e)} 
              onFocus={() => this.setState({focused: true})}
              onBlur={() => this.setState({focused: false})}
              disabled={this.props.disabled}
            />
        );
    }
}